import React from 'react';
import { useBlackforestImage } from '../hooks/useBlackforestImage';

interface BlackforestImageProps {
  prompt: string;
  alt: string;
  className?: string;
}

const BlackforestImage: React.FC<BlackforestImageProps> = ({ prompt, alt, className }) => {
  const { imageUrl, loading, error, generateImage } = useBlackforestImage();

  React.useEffect(() => {
    generateImage(prompt);
  }, [prompt, generateImage]);

  if (loading) {
    return (
      <div className={`flex items-center justify-center ${className}`}>
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-stardrive-orange"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`flex items-center justify-center text-red-500 ${className}`}>
        Failed to load image
      </div>
    );
  }

  if (!imageUrl) {
    return null;
  }

  return (
    <img
      src={imageUrl}
      alt={alt}
      className={className}
    />
  );
};

export default BlackforestImage;
