/**
 * Get the correct path for an asset in production or development
 * @param path The asset path relative to the public directory
 * @returns The correct path to use in the src attribute
 */
export const getAssetPath = (path: string | { default: string }): string => {
  // Handle Vite's imported assets
  if (typeof path === 'object' && path !== null && 'default' in path) {
    return path.default;
  }

  // Handle absolute URLs and data URLs
  if (typeof path === 'string' && (path.startsWith('http') || path.startsWith('data:'))) {
    return path;
  }

  // Clean the path
  const cleanPath = (typeof path === 'string' ? path : '').replace(/^\/+/, '');

  // If the path is in the assets directory, ensure it starts with a forward slash
  if (cleanPath.startsWith('assets/')) {
    return `/${cleanPath}`;
  }

  // For all other paths, assume they should be in the assets directory
  return `/assets/${cleanPath}`;
};

/**
 * Preload an image to ensure it's cached
 * @param src The image source URL
 * @returns A promise that resolves when the image is loaded
 */
export const preloadImage = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = reject;
    img.src = getAssetPath(src);
  });
};

/**
 * Check if an image exists and is accessible
 * @param path The image path to check
 * @returns A promise that resolves to true if the image exists and is accessible
 */
export const checkImageExists = async (path: string): Promise<boolean> => {
  try {
    const response = await fetch(getAssetPath(path), { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    console.error(`Failed to check image existence: ${path}`, error);
    return false;
  }
};

/**
 * Get image dimensions
 * @param src The image source URL
 * @returns A promise that resolves to the image dimensions
 */
export const getImageDimensions = (src: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = getAssetPath(src);
  });
};
