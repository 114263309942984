import React, { Suspense, useEffect } from 'react';
import HeroSectionTech from '../components/HeroSectionTech';

// Lazy load heavy components
const AchievementsSection = React.lazy(() => import('../components/AchievementsSection'));
const CoreTechnologiesSection = React.lazy(() => import('../components/CoreTechnologiesSection'));
const PropulsionStepsSection = React.lazy(() => import('../components/PropulsionStepsSection'));
const SimulationResultsSection = React.lazy(() => import('../components/SimulationResultsSection'));
const FAQSection = React.lazy(() => import('../components/FAQSection'));
const CTASection = React.lazy(() => import('../components/CTASection'));

// Loading fallback component
const LoadingFallback = () => (
  <div className="w-full h-64 flex items-center justify-center bg-stardrive-blue">
    <div className="w-12 h-12 border-4 border-stardrive-orange/20 border-t-stardrive-orange rounded-full animate-spin"></div>
  </div>
);

const TechnologyPage = () => {
  useEffect(() => {
    // Reset scroll position when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-stardrive-blue overflow-x-hidden">
      <HeroSectionTech />
      <div className="space-y-20"> {/* Increased spacing between sections */}
        <Suspense fallback={<LoadingFallback />}>
          <AchievementsSection />
        </Suspense>
        <Suspense fallback={<LoadingFallback />}>
          <CoreTechnologiesSection />
        </Suspense>
        <Suspense fallback={<LoadingFallback />}>
          <div className="min-h-screen"> {/* Ensure full viewport height */}
            <PropulsionStepsSection />
          </div>
        </Suspense>
        <Suspense fallback={<LoadingFallback />}>
          <SimulationResultsSection />
        </Suspense>
        <Suspense fallback={<LoadingFallback />}>
          <FAQSection />
        </Suspense>
        <Suspense fallback={<LoadingFallback />}>
          <CTASection />
        </Suspense>
      </div>
    </div>
  );
};

export default TechnologyPage;
