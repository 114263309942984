import React from 'react';

const LinkedInFeed = () => {
  const updates = [
    {
      type: 'company',
      title: 'Company Updates',
      description: 'Stay updated with our latest announcements, technology breakthroughs, and industry insights.',
      link: 'https://www.linkedin.com/company/stardrive-inc/posts/',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M16 8c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4zM12 14c-6.1 0-8 4-8 4v2h16v-2s-1.9-4-8-4z" />
        </svg>
      )
    },
    {
      type: 'jobs',
      title: 'Career Opportunities',
      description: 'Join our team of innovators and help shape the future of space transportation.',
      link: 'https://www.linkedin.com/company/stardrive-inc/jobs/',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8-2h4v2h-4V4zM4 8h16v3H4V8zm0 5h4v2H4v-2zm0 4h4v2H4v-2zm16 2H8v-2h12v2zm0-4H8v-2h12v2z" />
        </svg>
      )
    },
    {
      type: 'network',
      title: 'Our Network',
      description: 'Connect with our team and stay informed about industry events and partnerships.',
      link: 'https://www.linkedin.com/company/stardrive-inc/people/',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
        </svg>
      )
    }
  ];

  return (
    <div className="space-y-8">
      {/* Main Call-to-Action */}
      <div className="bg-gradient-to-r from-[#0A66C2] to-[#0077B5] p-8 rounded-lg text-white text-center">
        <h3 className="text-2xl font-bold mb-4">Connect with StarDrive</h3>
        <p className="mb-6 text-white/90">
          Follow us on LinkedIn to stay updated with our latest developments and opportunities
        </p>
        <a
          href="https://www.linkedin.com/company/stardrive-inc/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center bg-white text-[#0A66C2] px-6 py-3 rounded-lg font-bold hover:bg-opacity-90 transition-all duration-300"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
          Follow StarDrive
        </a>
      </div>

      {/* Update Cards */}
      <div className="grid gap-6 md:grid-cols-3">
        {updates.map((update, index) => (
          <a
            key={index}
            href={update.link}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white/5 p-6 rounded-lg hover:bg-white/10 transition-colors group"
          >
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <div className="w-12 h-12 rounded-full bg-stardrive-orange/20 flex items-center justify-center text-stardrive-orange">
                  {update.icon}
                </div>
                <h4 className="font-bold filtered-text">{update.title}</h4>
              </div>
              <p className="filtered-text text-sm text-gray-400">
                {update.description}
              </p>
              <span className="text-stardrive-orange group-hover:text-opacity-80 transition-colors inline-flex items-center text-sm">
                Learn More
                <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default LinkedInFeed;
