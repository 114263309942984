import OptimizedImage from '../components/OptimizedImage'
import { motion } from 'framer-motion'

const TeamPage = () => {
  const teamMembers = [
    {
      name: 'Jack Kahwati',
      role: 'CEO & Founder',
      image: 'Jack.png',
      bio: 'Visionary leader with extensive experience in aerospace technology.'
    },
    {
      name: 'Steve Jurvetson',
      role: 'Board Member',
      image: 'Steve.png',
      bio: 'Renowned venture capitalist and technology investor.'
    },
    {
      name: 'Peter Nolan',
      role: 'Board Member',
      image: 'Peter.png',
      bio: 'Strategic advisor with deep industry expertise.'
    },
    {
      name: 'Martin Casado',
      role: 'Board Member',
      image: 'Martin.png',
      bio: 'Technology pioneer and entrepreneurial leader.'
    },
    {
      name: 'Gerardo Interiano',
      role: 'Board Member',
      image: 'Gerardo.png',
      bio: 'Policy expert and strategic advisor.'
    },
    {
      name: 'Mian Mobeen',
      role: 'Board Member',
      image: 'Mian.png',
      bio: 'Technology innovator and industry veteran.'
    },
    {
      name: 'Nino Polizzi',
      role: 'Board Member',
      image: 'Nino.png',
      bio: 'Operations expert and strategic planner.'
    }
  ]

  return (
    <div className="min-h-screen bg-stardrive-blue text-white pt-24">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold text-stardrive-orange mb-6">Our Team</h1>
          <p className="text-xl text-white/80 max-w-3xl mx-auto">
            Meet the visionaries and industry leaders driving StarDrive's mission to revolutionize space transportation.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gradient-to-br from-gray-900 to-transparent p-6 rounded-2xl border border-stardrive-orange/20 hover:border-stardrive-orange/40 transition-all duration-300"
            >
              <div className="mb-6 relative w-full aspect-square">
                <OptimizedImage
                  src={member.image}
                  alt={member.name}
                  className="rounded-2xl object-cover w-full h-full"
                />
              </div>
              <h3 className="text-2xl font-bold text-stardrive-orange mb-2">{member.name}</h3>
              <p className="text-white/90 font-medium mb-3">{member.role}</p>
              <p className="text-white/70 text-sm leading-relaxed">{member.bio}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TeamPage
