import React from 'react';
import { motion } from 'framer-motion';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-stardrive-blue text-white">
      {/* Hero Section */}
      <section className="relative py-20 bg-gradient-to-b from-stardrive-blue to-stardrive-blue/95">
        <div className="max-w-6xl mx-auto px-4">
          <motion.h1 
            className="text-5xl md:text-6xl font-bold mb-6 text-stardrive-orange"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            About StarDrive
          </motion.h1>
          <motion.p 
            className="text-xl md:text-2xl mb-8 max-w-3xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Pioneering the future of space transportation through revolutionary electromagnetic propulsion technology.
          </motion.p>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-20 bg-gradient-to-b from-stardrive-blue/95 to-stardrive-blue">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-bold mb-8 text-stardrive-orange">Our Mission</h2>
            <p className="text-lg mb-6 leading-relaxed">
              StarDrive is dedicated to revolutionizing space transportation through the development of advanced electromagnetic propulsion systems. Our mission is to make space travel more efficient, sustainable, and accessible, enabling humanity's expansion into the solar system.
            </p>
            <p className="text-lg mb-6 leading-relaxed">
              By leveraging cutting-edge superconducting technology and innovative engineering solutions, we're creating a new paradigm in space logistics that will transform how we access and utilize space resources.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="py-20 bg-gradient-to-b from-stardrive-blue to-stardrive-blue/95">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-bold mb-8 text-stardrive-orange">Our Vision</h2>
            <p className="text-lg mb-6 leading-relaxed">
              We envision a future where electromagnetic propulsion systems form the backbone of space infrastructure, enabling regular interplanetary travel and the establishment of permanent human presence beyond Earth.
            </p>
            <p className="text-lg mb-6 leading-relaxed">
              Our technology will play a crucial role in establishing orbital manufacturing facilities, supporting lunar and Martian colonies, and facilitating deep space exploration missions.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className="py-20 bg-gradient-to-b from-stardrive-blue/95 to-stardrive-blue">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-bold mb-8 text-stardrive-orange">Core Values</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white/5 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-4 text-stardrive-orange">Innovation</h3>
                <p className="text-lg">
                  Pushing the boundaries of what's possible through continuous research and development in electromagnetic propulsion technology.
                </p>
              </div>
              <div className="bg-white/5 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-4 text-stardrive-orange">Sustainability</h3>
                <p className="text-lg">
                  Developing eco-friendly propulsion solutions that minimize environmental impact both on Earth and in space.
                </p>
              </div>
              <div className="bg-white/5 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-4 text-stardrive-orange">Collaboration</h3>
                <p className="text-lg">
                  Working closely with industry partners, research institutions, and space agencies to advance space transportation technology.
                </p>
              </div>
              <div className="bg-white/5 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-4 text-stardrive-orange">Excellence</h3>
                <p className="text-lg">
                  Maintaining the highest standards in engineering, safety, and reliability in all our systems and operations.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* History Section */}
      <section className="py-20 bg-gradient-to-b from-stardrive-blue to-stardrive-blue/95">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-bold mb-8 text-stardrive-orange">Our History</h2>
            <div className="space-y-8">
              <div className="flex items-start gap-6">
                <div className="w-24 flex-shrink-0 text-stardrive-orange font-bold">2020</div>
                <div>
                  <h3 className="text-xl font-bold mb-2">Company Founded</h3>
                  <p className="text-lg">StarDrive was established with the vision of revolutionizing space transportation through electromagnetic propulsion technology.</p>
                </div>
              </div>
              <div className="flex items-start gap-6">
                <div className="w-24 flex-shrink-0 text-stardrive-orange font-bold">2021</div>
                <div>
                  <h3 className="text-xl font-bold mb-2">First Prototype</h3>
                  <p className="text-lg">Successfully developed and tested our first electromagnetic ring prototype, demonstrating the viability of our core technology.</p>
                </div>
              </div>
              <div className="flex items-start gap-6">
                <div className="w-24 flex-shrink-0 text-stardrive-orange font-bold">2022</div>
                <div>
                  <h3 className="text-xl font-bold mb-2">Key Partnerships</h3>
                  <p className="text-lg">Established strategic partnerships with major aerospace companies and research institutions to accelerate technology development.</p>
                </div>
              </div>
              <div className="flex items-start gap-6">
                <div className="w-24 flex-shrink-0 text-stardrive-orange font-bold">2023</div>
                <div>
                  <h3 className="text-xl font-bold mb-2">Technology Breakthrough</h3>
                  <p className="text-lg">Achieved significant breakthroughs in superconducting materials and control systems, paving the way for full-scale implementation.</p>
                </div>
              </div>
              <div className="flex items-start gap-6">
                <div className="w-24 flex-shrink-0 text-stardrive-orange font-bold">2024</div>
                <div>
                  <h3 className="text-xl font-bold mb-2">Expanding Horizons</h3>
                  <p className="text-lg">Currently developing orbital gateway platforms and advancing our propulsion technology for interplanetary missions.</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
