import React from 'react';
import { motion } from 'framer-motion';
import BlackforestImage from '../components/BlackforestImage';

const AstroShieldPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-stardrive-blue text-white">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold mb-6">AstroShield Defense System</h1>
          <p className="text-xl text-white/80 max-w-3xl mx-auto">
            Next-generation spacecraft protection with real-time threat detection and adaptive countermeasures.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-8"
          >
            <div className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-8 rounded-2xl">
              <h2 className="text-3xl font-bold mb-4">Advanced Protection</h2>
              <p className="text-white/80">
                Our AstroShield system provides comprehensive protection against space debris, radiation, and other potential threats to spacecraft and their crews.
              </p>
            </div>

            <div className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-8 rounded-2xl">
              <h2 className="text-3xl font-bold mb-4">Real-Time Detection</h2>
              <p className="text-white/80">
                Utilizing advanced sensors and AI-driven analysis, AstroShield continuously monitors the spacecraft's environment for potential threats.
              </p>
            </div>

            <div className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-8 rounded-2xl">
              <h2 className="text-3xl font-bold mb-4">Adaptive Response</h2>
              <p className="text-white/80">
                When threats are detected, AstroShield automatically deploys appropriate countermeasures to ensure the safety of the spacecraft and its occupants.
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="relative"
          >
            <div className="sticky top-8">
              <BlackforestImage
                prompt="A futuristic spacecraft shield system with glowing energy barriers and advanced sensor arrays, digital art style"
                alt="AstroShield Defense System Visualization"
                className="w-full h-[600px] rounded-2xl object-cover"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AstroShieldPage;
