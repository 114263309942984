import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header className={`fixed top-0 left-0 right-0 p-4 flex justify-between items-center z-50 transition-all duration-300 ${
      isScrolled ? 'bg-gradient-to-r from-stardrive-blue to-stardrive-blue/90 backdrop-blur-sm' : 'bg-transparent'
    }`}>
      {/* Logo */}
      <div className="flex items-center">
        <Link to="/" className="flex items-center group">
          <span className="text-2xl md:text-3xl font-bold filtered-text tracking-wider bg-gradient-to-r from-stardrive-orange to-stardrive-orange/90 bg-clip-text text-transparent transform transition-transform duration-300 group-hover:scale-105">
            STARDRIVE
          </span>
        </Link>
      </div>
      
      {/* Desktop Navigation */}
      <nav className="hidden lg:flex items-center space-x-8">
        <Link 
          to="/about" 
          className="hover:text-stardrive-orange filtered-text transition-colors duration-300 text-lg"
        >
          About
        </Link>
        <Link 
          to="/team" 
          className="hover:text-stardrive-orange filtered-text transition-colors duration-300 text-lg"
        >
          Team
        </Link>
        <Link 
          to="/technology" 
          className="hover:text-stardrive-orange filtered-text transition-colors duration-300 text-lg"
        >
          Technology
        </Link>
        <Link 
          to="/solutions" 
          className="hover:text-stardrive-orange filtered-text transition-colors duration-300 text-lg"
        >
          Solutions
        </Link>
        <Link 
          to="/contact" 
          className="bg-stardrive-orange text-stardrive-blue px-6 py-2 rounded-full hover:bg-opacity-90 transition-all duration-300 filtered-image text-lg font-semibold"
        >
          Contact
        </Link>
      </nav>

      {/* Mobile Menu Button */}
      <div className="lg:hidden">
        <button 
          className="px-4 py-2 text-stardrive-orange hover:text-stardrive-orange/80 transition-colors duration-300 relative z-50"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        >
          {isMenuOpen ? 'Close' : 'Menu'}
        </button>

        {/* Mobile Menu Overlay */}
        {isMenuOpen && (
          <div className="fixed inset-0 z-40" onClick={() => setIsMenuOpen(false)}>
            {/* Backdrop */}
            <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" />
            
            {/* Menu Content */}
            <div 
              className="absolute top-[4.5rem] right-4 w-64 bg-stardrive-blue/95 rounded-lg shadow-lg overflow-hidden"
              onClick={e => e.stopPropagation()}
            >
              <nav className="py-2">
                <Link 
                  to="/about" 
                  className="flex items-center px-6 py-3 hover:bg-white/5 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span className="text-white">About</span>
                </Link>
                <Link 
                  to="/team" 
                  className="flex items-center px-6 py-3 hover:bg-white/5 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span className="text-white">Team</span>
                </Link>
                <Link 
                  to="/technology" 
                  className="flex items-center px-6 py-3 hover:bg-white/5 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span className="text-white">Technology</span>
                </Link>
                <Link 
                  to="/solutions" 
                  className="flex items-center px-6 py-3 hover:bg-white/5 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span className="text-white">Solutions</span>
                </Link>
                <div className="px-4 py-2">
                  <Link 
                    to="/contact" 
                    className="bg-stardrive-orange text-stardrive-blue px-6 py-2 rounded-full flex items-center justify-center hover:bg-opacity-90 transition-all duration-300 text-base font-semibold"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Contact
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
