import React from 'react';
import { motion } from 'framer-motion';

const HeroSectionTech: React.FC = () => {
  return (
    <section className="relative h-[600px] overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-stardrive-blue via-stardrive-blue/70 to-transparent"></div>
        {/* Dynamic Stars */}
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-1 h-1 bg-white rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              opacity: Math.random() * 0.7 + 0.3
            }}
            animate={{
              opacity: [0.3, 0.7, 0.3],
              scale: [1, 1.2, 1]
            }}
            transition={{
              duration: 2 + Math.random() * 2,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
          />
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative z-10 container mx-auto px-6 h-full flex flex-col justify-center"
      >
        <h1 className="text-5xl md:text-7xl font-bold text-stardrive-orange mb-6">
          Revolutionizing Space<br />Propulsion with StarDrive
        </h1>
        <p className="text-xl md:text-2xl text-white/90 max-w-2xl">
          Fuel-free acceleration. Scalable solutions. Sustainable innovation.
        </p>
      </motion.div>
      {/* Animated Gradient Overlay */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-stardrive-blue/50 via-transparent to-stardrive-blue/50"
        animate={{
          opacity: [0.3, 0.5, 0.3],
          backgroundPosition: ['0% 0%', '100% 100%', '0% 0%']
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          ease: "linear"
        }}
      />
    </section>
  );
};

export default HeroSectionTech;
