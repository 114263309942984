import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { getAssetPath } from '../utils/assetUtils'

const HeroSection = () => {
  const [isVideoLoading, setIsVideoLoading] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [videoError, setVideoError] = useState(false)
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const videoRef = useRef<HTMLIFrameElement>(null)
  const maxRetries = 2

  useEffect(() => {
    if (!isVideoLoading) return

    let startTime = Date.now()
    const maxLoadTime = 5000
    let animationFrameId: number

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime
      const progress = Math.min(Math.floor((elapsedTime / maxLoadTime) * 100), 90)
      
      setLoadingProgress(progress)
      
      if (progress < 90 && isVideoLoading) {
        animationFrameId = requestAnimationFrame(updateProgress)
      }
    }

    animationFrameId = requestAnimationFrame(updateProgress)

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
    }
  }, [isVideoLoading])

  const handleVideoLoad = useCallback(() => {
    console.log('Video iframe loaded successfully')
    setLoadingProgress(100)
    setTimeout(() => {
      setIsVideoLoading(false)
      setIsVideoVisible(true)
    }, 1000)
  }, [])

  const handleVideoError = useCallback((event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    console.warn(`Video load attempt ${retryCount + 1} failed.`, event)
    
    if (retryCount < maxRetries) {
      setRetryCount(prev => prev + 1)
      setIsVideoVisible(false)
      setTimeout(() => setIsVideoVisible(true), 1000)
    } else {
      console.warn('Video failed to load after retries, falling back to static background')
      setVideoError(true)
      setIsVideoLoading(false)
      setIsVideoVisible(false)
    }
  }, [retryCount])

  const features = [
    { 
      icon: 'rocket',
      title: 'Electromagnetic Propulsion',
      description: 'Revolutionary propulsion system enabling efficient, sustainable space exploration.'
    },
    { 
      icon: 'shield-alt',
      title: 'AstroShield Defense',
      description: 'Intelligent spacecraft protection with real-time threat detection and adaptive countermeasures.'
    },
    { 
      icon: 'cogs',
      title: 'Adaptive Technologies',
      description: 'Cutting-edge solutions for advanced space operations and orbital security.'
    }
  ]

  const fallbackStyle = {
    background: `
      linear-gradient(
        45deg,
        rgba(0, 18, 51, 0.95),
        rgba(0, 24, 69, 0.95)
      ),
      url('${getAssetPath('/assets/space-bg.jpg')}')
    `,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundBlendMode: 'overlay'
  }

  const videoUrl = "https://player.vimeo.com/video/230574607?background=1&autoplay=1&loop=1&autopause=0&muted=1&quality=720p"

  return (
    <section 
      className="relative overflow-hidden min-h-[800px] w-full bg-stardrive-blue -mt-[72px]"
      role="region"
      aria-label="StarDrive Introduction"
    >
      <div className="absolute inset-0 w-full h-full">
        {/* Loading State */}
        <div 
          className={`absolute inset-0 bg-gradient-to-br from-stardrive-blue to-stardrive-blue/90 transition-opacity duration-1000 z-20 ${
            isVideoLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
          aria-hidden={!isVideoLoading}
        >
          <div className="h-full flex flex-col items-center justify-center">
            <div 
              className="text-stardrive-orange mb-4"
              aria-hidden="true"
            >
              <i className="fas fa-rocket text-5xl animate-bounce"></i>
            </div>
            <div 
              className="text-stardrive-orange text-lg font-medium"
              role="status"
              aria-live="polite"
            >
              <span className="sr-only">Loading progress: </span>
              {loadingProgress < 100 ? 'Initializing StarDrive Systems' : 'Systems Ready'}
              <span className="ml-2">({loadingProgress}%)</span>
            </div>
            <div 
              className="w-64 h-2 bg-stardrive-orange/20 mt-4 rounded-full overflow-hidden"
              role="progressbar"
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={loadingProgress}
              aria-label="System initialization progress"
            >
              <div 
                className="h-full bg-stardrive-orange transition-all duration-300"
                style={{ width: `${loadingProgress}%` }}
              ></div>
            </div>
          </div>
        </div>
        
        {/* Video Background with Enhanced Fallback */}
        <div 
          className={`absolute inset-0 transition-opacity duration-1000 ${isVideoVisible ? 'opacity-100' : 'opacity-0'}`}
        >
          {!videoError ? (
            <iframe
              ref={videoRef}
              src={videoUrl}
              className="absolute w-[120vw] min-h-[67.5vw] h-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              title="StarDrive Technology Showcase"
              frameBorder="0"
              allow="autoplay; fullscreen"
              onLoad={handleVideoLoad}
              onError={handleVideoError}
              loading="lazy"
              aria-hidden="true"
            />
          ) : (
            <div 
              className="absolute inset-0"
              style={fallbackStyle}
              role="img"
              aria-label="Dynamic space background with stars"
            >
              <div className="absolute inset-0">
                {[...Array(50)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute w-[2px] h-[2px] bg-white rounded-full animate-twinkle-1"
                    style={{
                      left: `${Math.random() * 100}%`,
                      top: `${Math.random() * 100}%`,
                      animationDelay: `${Math.random() * 3}s`
                    }}
                    aria-hidden="true"
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-r from-stardrive-blue/95 via-stardrive-blue/70 to-transparent opacity-90"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-stardrive-blue opacity-90"></div>
      </div>

      {/* Content Section */}
      <div className="relative z-10 pt-40 pb-16 px-8 md:px-16 space-y-8 h-full flex flex-col justify-center max-w-7xl mx-auto">
        <h1 className="text-4xl md:text-7xl font-bold text-stardrive-orange mb-6 leading-tight tracking-tight transform transition-transform duration-500 translate-y-0 group-hover:-translate-y-2">
          Revolutionizing<br />Space Exploration<br />and Defense
        </h1>
        <p className="text-white/90 text-lg md:text-xl leading-relaxed max-w-2xl transform transition-all duration-500 translate-y-0 group-hover:-translate-y-2 delay-75">
          StarDrive combines breakthrough electromagnetic propulsion, advanced defense systems, and adaptive technologies to reshape humanity's reach into the stars.
        </p>

        {/* Interactive Features */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="group/feature bg-stardrive-blue/30 backdrop-blur-sm hover:bg-stardrive-blue/40 transition-all duration-300 rounded-lg p-6"
            >
              <div className="flex items-center mb-3">
                <div className="w-12 h-12 rounded-full bg-stardrive-orange/20 flex items-center justify-center mr-3 group-hover/feature:bg-stardrive-orange/30 transition-colors duration-300">
                  <i 
                    className={`fas fa-${feature.icon} text-2xl text-stardrive-orange`}
                    aria-hidden="true"
                  ></i>
                </div>
                <h3 className="text-lg font-semibold text-stardrive-orange group-hover/feature:text-stardrive-orange/90">
                  {feature.title}
                </h3>
              </div>
              <p className="text-white/80 text-sm mb-2">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Call-to-Action Buttons */}
        <div className="flex flex-wrap gap-4 md:gap-6 transform transition-all duration-500 translate-y-0 group-hover:-translate-y-2 delay-150">
          <Link 
            to="/technology" 
            className="group/button bg-stardrive-orange px-6 md:px-8 py-3 md:py-4 rounded-full text-stardrive-blue hover:bg-opacity-90 transition-all duration-300 text-base md:text-lg font-semibold flex items-center relative overflow-hidden shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-stardrive-orange focus:ring-offset-2 focus:ring-offset-stardrive-blue"
            role="button"
            aria-label="Explore Our Technology"
          >
            <span className="relative z-10">Explore Our Technology</span>
            <i className="fas fa-arrow-right ml-3 transform transition-transform duration-300 group-hover/button:translate-x-1"></i>
          </Link>
          <Link 
            to="/partnerships" 
            className="group/button bg-transparent border-2 border-stardrive-orange px-6 md:px-8 py-3 md:py-4 rounded-full text-stardrive-orange hover:bg-stardrive-orange/10 transition-all duration-300 text-base md:text-lg font-semibold flex items-center relative overflow-hidden shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-stardrive-orange focus:ring-offset-2 focus:ring-offset-stardrive-blue"
            role="button"
            aria-label="Partner with Us"
          >
            <span className="relative z-10">Partner with Us</span>
            <i className="fas fa-arrow-right ml-3 transform transition-transform duration-300 group-hover/button:translate-x-1"></i>
          </Link>
        </div>
      </div>

      {/* NoScript Fallback */}
      <noscript>
        <div className="absolute inset-0 bg-stardrive-blue flex items-center justify-center text-center p-8">
          <div>
            <h1 className="text-4xl font-bold mb-4 text-stardrive-orange">StarDrive: Revolutionizing Space Exploration and Defense</h1>
            <p className="text-white/90 mb-4">Please enable JavaScript to experience our interactive features. StarDrive combines breakthrough electromagnetic propulsion and advanced defense systems.</p>
            <div className="mt-4 space-y-4">
              <a href="/technology" className="block text-stardrive-orange hover:underline">Explore Our Technology</a>
              <a href="/partnerships" className="block text-stardrive-orange hover:underline">Partner with Us</a>
            </div>
          </div>
        </div>
      </noscript>
    </section>
  )
}

export default HeroSection
