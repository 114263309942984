import MainContent from '../components/MainContent'

const SolutionsPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Solutions</h1>
      <MainContent />
    </div>
  )
}

export default SolutionsPage
