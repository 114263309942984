import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Data Collection and Usage</h2>
        <p className="mb-4">
          At StarDrive, we collect and process personal information in accordance with applicable data protection laws, including GDPR and CCPA. 
          Your data will never be shared without consent, except as required by law or to deliver our services.
        </p>
        <h3 className="text-xl font-semibold mb-2">Information We Collect</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Contact information (name, email address, phone number)</li>
          <li>Professional information (resumes, job applications)</li>
          <li>Technical data (IP address, browser type, device information)</li>
          <li>Usage data (website interaction, preferences)</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>To communicate with you about our services and updates</li>
          <li>To process job applications and recruitment</li>
          <li>To improve our website and services</li>
          <li>To comply with legal obligations</li>
          <li>To analyze website traffic and user behavior</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
        <p className="mb-4">
          We use trusted third-party services for:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Website analytics (Google Analytics)</li>
          <li>Video hosting (Vimeo)</li>
          <li>Cloud infrastructure services</li>
        </ul>
        <p className="mb-4">
          These services may collect and process your data according to their own privacy policies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Data Retention</h2>
        <p className="mb-4">
          We retain personal information only for as long as necessary to fulfill the purposes for which it was collected,
          including legal, accounting, or reporting requirements.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
        <p className="mb-4">Under GDPR and other applicable laws, you have the right to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Object to data processing</li>
          <li>Request data portability</li>
          <li>Withdraw consent at any time</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
        <p className="mb-4">
          For any privacy-related inquiries or to exercise your rights, please contact us at:
          <br />
          Email: privacy@thestardrive.com
          <br />
          Address: StarDrive Headquarters, San Francisco, CA
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Updates to This Policy</h2>
        <p className="mb-4">
          We may update this privacy policy from time to time. Any changes will be posted on this page with a revised effective date.
        </p>
      </section>

      <footer className="text-sm text-gray-600">
        Last updated: March 2024
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;
