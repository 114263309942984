import React from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './pages/HomePage'
import ContactPage from './pages/ContactPage'
import MediaCenterPage from './pages/MediaCenterPage'
import PartnershipsPage from './pages/PartnershipsPage'
import CareersPage from './pages/CareersPage'
import SolutionsPage from './pages/SolutionsPage'
import TechnologyPage from './pages/TechnologyPage'
import AstroShieldPage from './pages/AstroShieldPage'
import TeamPage from './pages/TeamPage'
import AboutPage from './pages/AboutPage'
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage'
import TermsOfServicePage from './pages/legal/TermsOfServicePage'
import CookiePolicyPage from './pages/legal/CookiePolicyPage'
import AccessibilityPage from './pages/legal/AccessibilityPage'

const App = () => {
  const location = useLocation();

  // Scroll to top on route change
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex flex-col bg-stardrive-blue text-white overflow-x-hidden">
      <Header />
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/media" element={<MediaCenterPage />} />
          <Route path="/partnerships" element={<PartnershipsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/technology" element={<TechnologyPage />} />
          <Route path="/astroshield" element={<AstroShieldPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/legal/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/legal/cookie-policy" element={<CookiePolicyPage />} />
          <Route path="/legal/accessibility" element={<AccessibilityPage />} />
          {/* Catch-all route - redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
