import React from 'react';

const TermsOfServicePage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Agreement to Terms</h2>
        <p className="mb-4">
          By accessing and using StarDrive's website, you agree to be bound by these Terms of Service
          and all applicable laws and regulations. If you do not agree with any of these terms, you
          are prohibited from using this site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Intellectual Property Rights</h2>
        <p className="mb-4">
          All content on this website, including but not limited to text, graphics, logos, images,
          videos, and software, is the property of StarDrive and is protected by intellectual
          property laws. Our trademarks and trade dress may not be used without our prior written permission.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Use License</h2>
        <p className="mb-4">
          Permission is granted to temporarily access materials on StarDrive's website for personal,
          non-commercial viewing only. This is the grant of a license, not a transfer of title, and
          under this license you may not:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Modify or copy the materials</li>
          <li>Use the materials for any commercial purpose</li>
          <li>Attempt to reverse engineer any software contained on the website</li>
          <li>Remove any copyright or proprietary notations</li>
          <li>Transfer the materials to another person</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Export Control</h2>
        <p className="mb-4">
          StarDrive's technologies may be subject to U.S. export controls, including the International
          Traffic in Arms Regulations (ITAR) and Export Administration Regulations (EAR). Unauthorized
          export, transfer, or sharing of technical data is prohibited.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Disclaimer</h2>
        <p className="mb-4">
          The materials on StarDrive's website are provided on an 'as is' basis. StarDrive makes no
          warranties, expressed or implied, and hereby disclaims and negates all other warranties
          including, without limitation, implied warranties or conditions of merchantability, fitness
          for a particular purpose, or non-infringement of intellectual property.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Safety and Compliance</h2>
        <p className="mb-4">
          StarDrive conducts extensive safety testing on all systems described on this website.
          While we aim for the highest safety standards, operational risks inherent to aerospace
          technology may exist. We comply with all applicable regulations, including FAA, NASA,
          and other relevant agency requirements.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Limitations</h2>
        <p className="mb-4">
          In no event shall StarDrive or its suppliers be liable for any damages (including, without
          limitation, damages for loss of data or profit, or due to business interruption) arising
          out of the use or inability to use the materials on our website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
        <p className="mb-4">
          These terms and conditions are governed by and construed in accordance with the laws of
          the United States and the State of California. Any disputes relating to these terms and
          conditions will be subject to the exclusive jurisdiction of the courts of California.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
        <p className="mb-4">
          StarDrive may revise these terms of service at any time without notice. By using this
          website, you agree to be bound by the current version of these terms of service.
        </p>
      </section>

      <footer className="text-sm text-gray-600">
        Last updated: March 2024
      </footer>
    </div>
  );
};

export default TermsOfServicePage;
