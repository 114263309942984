import PartnersBar from '../components/PartnersBar'

const PartnershipsPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Partnerships</h1>
      <PartnersBar />
    </div>
  )
}

export default PartnershipsPage
