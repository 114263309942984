import { useState } from 'react';
import { fetchBlackforestImage } from '../utils/blackforest';

export function useBlackforestImage() {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const generateImage = async (prompt: string) => {
    setLoading(true);
    setError(null);
    try {
      const url = await fetchBlackforestImage(prompt);
      setImageUrl(url);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to generate image'));
    } finally {
      setLoading(false);
    }
  };

  return {
    imageUrl,
    loading,
    error,
    generateImage
  };
}
