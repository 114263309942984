import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const [email, setEmail] = useState('')

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle newsletter subscription
    console.log('Newsletter subscription:', email)
    setEmail('')
  }

  return (
    <footer className="bg-stardrive-blue py-16">
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-12">
          {/* Contact Information */}
          <div className="space-y-4 md:col-span-2">
            <h3 className="text-xl font-bold filtered-text mb-6">Contact</h3>
            <div className="space-y-2">
              <p className="filtered-text">StarDrive Headquarters</p>
              <p className="filtered-text">San Francisco, CA</p>
              <a href="mailto:jack@thestardrive.com" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                jack@thestardrive.com
              </a>
              
              {/* Social Media Links */}
              <div className="flex space-x-4 pt-4">
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-stardrive-orange transition-colors duration-300">
                  <i className="fab fa-linkedin text-xl"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-stardrive-orange transition-colors duration-300">
                  <i className="fab fa-twitter text-xl"></i>
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-stardrive-orange transition-colors duration-300">
                  <i className="fab fa-youtube text-xl"></i>
                </a>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold filtered-text mb-6">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/technology" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Technology
                </Link>
              </li>
              <li>
                <Link to="/solutions" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Solutions
                </Link>
              </li>
              <li>
                <Link to="/careers" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Careers
                </Link>
              </li>
            </ul>
          </div>

          {/* Solutions */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold filtered-text mb-6">Solutions</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/solutions#earth-to-orbit" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Earth-to-Orbit Deployment
                </Link>
              </li>
              <li>
                <Link to="/solutions#orbital-logistics" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Orbital Logistics
                </Link>
              </li>
              <li>
                <Link to="/astroshield" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  AstroShield
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold filtered-text mb-6">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/media" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Media Center
                </Link>
              </li>
              <li>
                <Link to="/partnerships" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Partnerships
                </Link>
              </li>
              <li>
                <Link to="/contact" className="filtered-text hover:text-stardrive-orange transition-colors duration-300">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Newsletter Subscription */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold filtered-text mb-6">Stay Updated</h3>
            <form onSubmit={handleSubscribe} className="space-y-4">
              <div>
                <label htmlFor="email" className="sr-only">Email address</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-stardrive-orange"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-stardrive-orange text-stardrive-blue rounded-lg font-semibold hover:bg-opacity-90 transition-all duration-300"
              >
                Subscribe to Newsletter
              </button>
            </form>
          </div>
        </div>

        {/* Legal Links */}
        <div className="mt-16 pt-8 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="filtered-text">© {new Date().getFullYear()} StarDrive. All rights reserved.</p>
            <div className="flex space-x-6">
              <Link to="/legal/privacy-policy" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                Privacy Policy
              </Link>
              <Link to="/legal/terms-of-service" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                Terms of Service
              </Link>
              <Link to="/legal/cookie-policy" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                Cookie Policy
              </Link>
              <Link to="/legal/accessibility" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                Accessibility
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
