import React from 'react';

const CookiePolicyPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8">Cookie Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What Are Cookies</h2>
        <p className="mb-4">
          Cookies are small text files that are placed on your computer or mobile device when you visit
          our website. They are widely used to make websites work more efficiently and provide useful
          information to website owners.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How We Use Cookies</h2>
        <p className="mb-4">
          StarDrive uses cookies to improve your browsing experience, analyze site traffic, and
          understand where our audience is coming from. Here are the types of cookies we use:
        </p>
        
        <h3 className="text-xl font-semibold mb-2">Essential Cookies</h3>
        <p className="mb-4">
          These cookies are necessary for the website to function properly. They enable basic
          functions like page navigation and access to secure areas of the website.
        </p>

        <h3 className="text-xl font-semibold mb-2">Analytics Cookies</h3>
        <p className="mb-4">
          We use analytics cookies (via Google Analytics) to understand how visitors interact
          with our website. This helps us improve our website's functionality and content.
        </p>

        <h3 className="text-xl font-semibold mb-2">Functional Cookies</h3>
        <p className="mb-4">
          These cookies enable enhanced functionality and personalization, such as remembering
          your preferences and settings.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Third-Party Cookies</h2>
        <p className="mb-4">
          Some cookies are placed by third-party services that appear on our pages. These include:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Google Analytics (for website analytics)</li>
          <li>Vimeo (for video content)</li>
          <li>Social media plugins (if you choose to interact with our social media features)</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Managing Cookies</h2>
        <p className="mb-4">
          You can control and/or delete cookies as you wish. You can delete all cookies that are
          already on your computer and you can set most browsers to prevent them from being placed.
          However, if you do this, you may have to manually adjust some preferences every time you
          visit our site and some features may not work as intended.
        </p>
        <p className="mb-4">
          To learn more about cookies and how to manage them, visit:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>www.aboutcookies.org</li>
          <li>www.allaboutcookies.org</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Cookie Consent</h2>
        <p className="mb-4">
          When you first visit our website, you will be shown a cookie consent banner. By clicking
          "Accept All Cookies", you consent to the use of all cookies described in this policy.
          You can also choose to manage your preferences by clicking "Manage Preferences".
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Updates to This Policy</h2>
        <p className="mb-4">
          We may update this Cookie Policy from time to time to reflect changes in our practices
          or for operational, legal, or regulatory reasons. We encourage you to periodically
          review this page for the latest information on our cookie practices.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about our use of cookies, please contact us at:
          <br />
          Email: privacy@thestardrive.com
        </p>
      </section>

      <footer className="text-sm text-gray-600">
        Last updated: March 2024
      </footer>
    </div>
  );
};

export default CookiePolicyPage;
