import React from 'react'
import OptimizedImage from './OptimizedImage'

const PartnersBar = () => {
  return (
    <div className="w-full bg-gradient-to-r from-stardrive-blue via-stardrive-blue/95 to-stardrive-blue py-16">
      <div className="max-w-6xl mx-auto px-8">
        <h2 className="text-3xl font-bold mb-12 filtered-text text-center">Trusted By Industry Leaders</h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* Space Force */}
          <div className="bg-white/5 p-8 rounded-lg flex flex-col items-center text-center hover:bg-white/10 transition-all duration-300 transform hover:scale-105">
            <div className="h-40 flex items-center justify-center mb-6">
              <OptimizedImage 
                src="/Seal_of_the_United_States_Space_Force.svg.png"
                alt="United States Space Force Seal" 
                className="max-h-full max-w-full object-contain"
              />
            </div>
            <h3 className="text-2xl font-bold mb-3 filtered-text">United States Space Force</h3>
            <p className="text-sm filtered-text text-white/80 max-w-sm">Strategic partner in advancing space defense capabilities and orbital operations.</p>
          </div>
          {/* LongShot */}
          <div className="bg-white/5 p-8 rounded-lg flex flex-col items-center text-center hover:bg-white/10 transition-all duration-300 transform hover:scale-105">
            <div className="h-40 flex items-center justify-center mb-6">
              <OptimizedImage 
                src="/LongShotLogoNew.png"
                alt="LongShot Logo" 
                className="max-h-full max-w-full object-contain"
              />
            </div>
            <h3 className="text-2xl font-bold mb-3 filtered-text">LongShot</h3>
            <p className="text-sm filtered-text text-white/80 max-w-sm">Collaborating on advanced propulsion technologies and aerospace innovations.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersBar
