import React from 'react';

const AccessibilityPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8">Accessibility Statement</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Our Commitment</h2>
        <p className="mb-4">
          StarDrive is committed to ensuring digital accessibility for people with disabilities. We are
          continually improving the user experience for everyone and applying the relevant accessibility
          standards to achieve this.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Conformance Status</h2>
        <p className="mb-4">
          The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and
          developers to improve accessibility for people with disabilities. It defines three levels of
          conformance: Level A, Level AA, and Level AAA. StarDrive's website is partially conformant
          with WCAG 2.1 level AA standards. Partially conformant means that some parts of the content
          do not fully conform to the accessibility standard.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Accessibility Features</h2>
        <p className="mb-4">Our website includes the following accessibility features:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Keyboard Navigation: All functionality is operable through a keyboard interface</li>
          <li>ARIA Labels: We use ARIA attributes to improve navigation for screen readers</li>
          <li>Color Contrast: Our color choices meet WCAG 2.1 Level AA contrast ratios</li>
          <li>Alt Text: All images have appropriate alternative text descriptions</li>
          <li>Resizable Text: Text can be resized up to 200% without loss of functionality</li>
          <li>Consistent Navigation: Navigation mechanisms are consistent across pages</li>
          <li>Focus Indicators: Visible focus indicators for keyboard navigation</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Technical Specifications</h2>
        <p className="mb-4">
          Accessibility of StarDrive's website relies on the following technologies to work with the
          particular combination of web browser and any assistive technologies or plugins installed on
          your computer:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>HTML5</li>
          <li>WAI-ARIA</li>
          <li>CSS3</li>
          <li>JavaScript</li>
        </ul>
        <p className="mb-4">
          These technologies are relied upon for conformance with the accessibility standards used.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Known Limitations</h2>
        <p className="mb-4">
          Despite our best efforts to ensure accessibility of the StarDrive website, there may be some
          limitations. Below is a description of known limitations, and potential solutions. Please
          contact us if you observe an issue not listed below.
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Some older PDF documents may not be fully accessible. We are working to update these documents.</li>
          <li>Some video content may not have captions. We are working to add captions to all videos.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Feedback</h2>
        <p className="mb-4">
          We welcome your feedback on the accessibility of the StarDrive website. Please let us know if
          you encounter accessibility barriers:
        </p>
        <ul className="list-none mb-4">
          <li>Email: accessibility@thestardrive.com</li>
          <li>Phone: (XXX) XXX-XXXX</li>
          <li>Address: StarDrive Headquarters, San Francisco, CA</li>
        </ul>
        <p className="mb-4">
          We try to respond to feedback within 2 business days.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Assessment Approach</h2>
        <p className="mb-4">
          StarDrive assessed the accessibility of our website by the following approaches:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Self-evaluation</li>
          <li>External evaluation</li>
          <li>Automated evaluation tools</li>
          <li>User testing with assistive technologies</li>
        </ul>
      </section>

      <footer className="text-sm text-gray-600">
        Last updated: March 2024
      </footer>
    </div>
  );
};

export default AccessibilityPage;
