import React from 'react'
import HeroSection from './HeroSection'
import PartnersBar from './PartnersBar'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import OptimizedImage from './OptimizedImage'

const MainContent = () => {
  return (
    <main>
      <div className="-mx-4">
        <HeroSection />
      </div>
      
      <PartnersBar />
      
      <div className="p-8 space-y-24">
        {/* Mission Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-gradient-to-br from-stardrive-orange to-stardrive-orange/90 p-12 text-stardrive-blue relative rounded-2xl group hover:shadow-2xl transition-all duration-500"
        >
          <div className="absolute inset-0 orange-blue-overlay rounded-2xl"></div>
          <div className="relative z-10 space-y-6 max-w-4xl mx-auto text-center">
            <h2 className="text-5xl font-bold filtered-text mb-8">Expanding the Boundaries of Possibility</h2>
            <p className="text-xl filtered-text leading-relaxed">Our mission is to deliver cutting-edge solutions that redefine the way we explore, secure, and operate in space. From next-generation propulsion to adaptive defense systems, StarDrive is building a future where interstellar travel and orbital security are seamless.</p>
          </div>
        </motion.section>

        {/* Core Technologies Section */}
        <section className="space-y-12">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-stardrive-orange text-center"
          >
            Revolutionary Space Technologies
          </motion.h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Earth-to-Orbit Deployment */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-8 rounded-2xl hover:shadow-2xl transition-all duration-500 group"
            >
              <div className="space-y-6">
                <h3 className="text-2xl font-bold filtered-text group-hover:text-stardrive-orange transition-colors duration-300">Electromagnetic Propulsion</h3>
                <p className="filtered-text leading-relaxed">Revolutionary propulsion system enabling efficient Earth-to-orbit deployment without traditional fuel, drastically reducing launch costs.</p>
                <div className="h-48 bg-stardrive-blue/30 rounded-lg overflow-hidden">
                  <OptimizedImage 
                    src="/electromagnetic-propulsion.jpeg"
                    alt="Electromagnetic Propulsion"
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                </div>
                <Link to="/technology" className="text-stardrive-orange filtered-text text-lg inline-flex items-center hover:opacity-80 transition-all duration-300 group/link">
                  Learn More <i className="fas fa-arrow-right ml-2 transform group-hover/link:translate-x-1 transition-transform duration-300"></i>
                </Link>
              </div>
            </motion.div>

            {/* Orbital Logistics */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-8 rounded-2xl hover:shadow-2xl transition-all duration-500 group"
            >
              <div className="space-y-6">
                <h3 className="text-2xl font-bold filtered-text group-hover:text-stardrive-orange transition-colors duration-300">Orbital Logistics</h3>
                <p className="filtered-text leading-relaxed">Advanced platforms enabling sustainable space operations, from manufacturing to material storage and interplanetary missions.</p>
                <div className="h-48 bg-stardrive-blue/30 rounded-lg overflow-hidden">
                  <OptimizedImage 
                    src="/Orbital-Logistics.jpeg"
                    alt="Orbital Logistics"
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                </div>
                <Link to="/solutions" className="text-stardrive-orange filtered-text text-lg inline-flex items-center hover:opacity-80 transition-all duration-300 group/link">
                  Explore Solutions <i className="fas fa-arrow-right ml-2 transform group-hover/link:translate-x-1 transition-transform duration-300"></i>
                </Link>
              </div>
            </motion.div>

            {/* AstroShield */}
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-8 rounded-2xl hover:shadow-2xl transition-all duration-500 group"
            >
              <div className="space-y-6">
                <h3 className="text-2xl font-bold filtered-text group-hover:text-stardrive-orange transition-colors duration-300">AstroShield Defense</h3>
                <p className="filtered-text leading-relaxed">Next-generation spacecraft protection with real-time threat detection and adaptive countermeasures.</p>
                <div className="h-48 bg-stardrive-blue/30 rounded-lg overflow-hidden">
                  <OptimizedImage 
                    src="/Astroshield.jpeg"
                    alt="AstroShield"
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                </div>
                <Link to="/astroshield" className="text-stardrive-orange filtered-text text-lg inline-flex items-center hover:opacity-80 transition-all duration-300 group/link">
                  Discover AstroShield <i className="fas fa-arrow-right ml-2 transform group-hover/link:translate-x-1 transition-transform duration-300"></i>
                </Link>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Innovation Timeline */}
        <section className="bg-gradient-to-br from-stardrive-blue to-stardrive-blue/80 p-12 rounded-2xl">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-stardrive-orange text-center mb-16"
          >
            Innovation Timeline
          </motion.h2>
          
          <div className="max-w-3xl mx-auto">
            <div className="space-y-12">
              {[
                { year: '2023', event: 'StarDrive founded', delay: 0 },
                { year: '2024', event: 'Successful electromagnetic propulsion tests', delay: 0.2 },
                { year: '2025', event: "AstroShield integrated with Space Force's TAP Lab", delay: 0.4 },
                { year: '2026', event: 'Development of scalable lunar mass driver prototypes', delay: 0.6 }
              ].map((milestone, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: milestone.delay }}
                  className="flex items-start gap-8 group"
                >
                  <div className="flex-shrink-0 w-32">
                    <span className="text-stardrive-orange font-bold text-2xl">{milestone.year}</span>
                  </div>
                  <div className="flex-grow">
                    <div className="h-0.5 bg-stardrive-orange/30 relative top-4">
                      <motion.div 
                        className="absolute left-0 w-4 h-4 bg-stardrive-orange rounded-full -translate-y-1/3"
                        whileHover={{ scale: 1.5 }}
                      />
                    </div>
                  </div>
                  <div className="flex-grow-[2]">
                    <p className="text-white/90 text-xl">{milestone.event}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Join Us Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-gradient-to-br from-white to-white/90 text-stardrive-blue p-12 rounded-2xl"
        >
          <div className="max-w-4xl mx-auto text-center space-y-8">
            <h2 className="text-4xl font-bold">Join the Mission</h2>
            <p className="text-xl leading-relaxed">Be part of the team revolutionizing space exploration and defense. Work on groundbreaking technologies that will shape humanity's future among the stars.</p>
            <div className="mt-12">
              <Link to="/careers" className="inline-flex items-center justify-center px-12 py-4 bg-stardrive-orange text-stardrive-blue rounded-full text-xl font-semibold hover:bg-opacity-90 transition-all duration-300 hover:scale-105">
                View Open Positions <i className="fas fa-arrow-right ml-3"></i>
              </Link>
            </div>
          </div>
        </motion.section>
      </div>
    </main>
  )
}

export default MainContent
