import React from 'react'

const JobCategory = ({ title, positions }: {
  title: string;
  positions: Array<{
    title: string;
    location: string;
    type: string;
  }>;
}) => (
  <div className="bg-white/5 p-6 rounded-lg">
    <h3 className="text-xl font-bold mb-4 filtered-text">{title}</h3>
    <div className="space-y-4">
      {positions.map((position, index) => (
        <div key={index} className="bg-white/10 p-4 rounded-lg">
          <div className="flex justify-between items-start mb-2">
            <h4 className="font-bold filtered-text">{position.title}</h4>
            <span className="text-stardrive-orange text-sm">{position.type}</span>
          </div>
          <p className="text-sm filtered-text">{position.location}</p>
        </div>
      ))}
    </div>
  </div>
)

const CareersPage = () => {
  const jobCategories = [
    {
      title: "Engineering",
      positions: [
        {
          title: "Senior Propulsion Engineer",
          location: "San Francisco, CA",
          type: "Full-time"
        },
        {
          title: "Aerospace Systems Engineer",
          location: "San Francisco, CA",
          type: "Full-time"
        }
      ]
    },
    {
      title: "Research & Development",
      positions: [
        {
          title: "Research Scientist - Electromagnetic Systems",
          location: "San Francisco, CA",
          type: "Full-time"
        }
      ]
    },
    {
      title: "Operations",
      positions: [
        {
          title: "Launch Operations Manager",
          location: "San Francisco, CA",
          type: "Full-time"
        }
      ]
    }
  ]

  return (
    <div className="min-h-screen bg-stardrive-blue text-white p-8">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Main Careers Section */}
        <section className="space-y-6">
          <h1 className="text-4xl font-bold filtered-text">Careers at StarDrive</h1>
          <div className="bg-white/5 p-8 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 filtered-text">Join Our Team</h2>
            <p className="text-lg mb-6 filtered-text">
              Looking to join the StarDrive team? Email your resume and cover letter to{' '}
              <a href="mailto:jack@thestardrive.com?subject=[Career Opportunities]" className="text-stardrive-orange">
                jack@thestardrive.com
              </a>{' '}
              with [Career Opportunities] in the subject line.
            </p>
            <a
              href="mailto:jack@thestardrive.com?subject=[Career Opportunities]"
              className="bg-stardrive-orange text-stardrive-blue px-6 py-3 rounded font-bold hover:bg-opacity-90 transition-all duration-300 inline-flex items-center"
            >
              <i className="fas fa-paper-plane mr-2"></i>
              Submit Your Application
            </a>
          </div>
        </section>

        {/* Why Join StarDrive */}
        <section className="space-y-6">
          <h2 className="text-2xl font-bold filtered-text">Why Join StarDrive?</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white/10 p-6 rounded-lg">
              <i className="fas fa-rocket text-stardrive-orange text-3xl mb-4"></i>
              <h3 className="text-xl font-bold mb-2 filtered-text">Pioneering Technology</h3>
              <p className="filtered-text">Work on cutting-edge electromagnetic propulsion systems</p>
            </div>
            <div className="bg-white/10 p-6 rounded-lg">
              <i className="fas fa-users text-stardrive-orange text-3xl mb-4"></i>
              <h3 className="text-xl font-bold mb-2 filtered-text">Exceptional Team</h3>
              <p className="filtered-text">Collaborate with world-class experts in aerospace</p>
            </div>
            <div className="bg-white/10 p-6 rounded-lg">
              <i className="fas fa-chart-line text-stardrive-orange text-3xl mb-4"></i>
              <h3 className="text-xl font-bold mb-2 filtered-text">Growth Opportunities</h3>
              <p className="filtered-text">Accelerate your career in a rapidly expanding industry</p>
            </div>
          </div>
        </section>

        {/* Open Positions */}
        <section className="space-y-6">
          <h2 className="text-2xl font-bold filtered-text">Open Positions</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {jobCategories.map((category, index) => (
              <JobCategory key={index} {...category} />
            ))}
          </div>
        </section>

        {/* Benefits */}
        <section className="bg-white/5 p-8 rounded-lg space-y-6">
          <h2 className="text-2xl font-bold filtered-text">Benefits & Perks</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-xl font-bold mb-4 filtered-text">Health & Wellness</h3>
              <ul className="space-y-2 filtered-text">
                <li className="flex items-center"><i className="fas fa-check text-stardrive-orange mr-2"></i>Comprehensive health insurance</li>
                <li className="flex items-center"><i className="fas fa-check text-stardrive-orange mr-2"></i>Dental and vision coverage</li>
                <li className="flex items-center"><i className="fas fa-check text-stardrive-orange mr-2"></i>Mental health support</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4 filtered-text">Work-Life Balance</h3>
              <ul className="space-y-2 filtered-text">
                <li className="flex items-center"><i className="fas fa-check text-stardrive-orange mr-2"></i>Flexible work hours</li>
                <li className="flex items-center"><i className="fas fa-check text-stardrive-orange mr-2"></i>Generous PTO policy</li>
                <li className="flex items-center"><i className="fas fa-check text-stardrive-orange mr-2"></i>Remote work options</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CareersPage
