import React from 'react'
import MainContent from '../components/MainContent'

const HomePage = () => {
  return (
    <div className="w-full">
      <MainContent />
    </div>
  )
}

export default HomePage
