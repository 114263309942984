import LinkedInFeed from '../components/LinkedInFeed'

const MediaCenterPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Media Center</h1>
      <LinkedInFeed />
    </div>
  )
}

export default MediaCenterPage
