const API_KEY = import.meta.env.VITE_BLACKFOREST_API_KEY || ''

export async function fetchBlackforestImage(prompt: string): Promise<string> {
  try {
    const response = await fetch('https://api.blackforest.ai/v1/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${API_KEY}`
      },
      body: JSON.stringify({ prompt })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.url;
  } catch (error) {
    console.error('Error fetching Blackforest image:', error);
    throw error;
  }
}
